body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="radio"] {
  accent-color: black;
}

input[type="checkbox"] {
  accent-color: black;
}

@font-face {
  font-family: "Flama";
  src: local("Flama"), url("./fonts/flama/Flama-Basic.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Flama";
  src: local("Flama"), url("./fonts/flama/Flama-Book.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Flama";
  src: local("Flama"),
    url("./fonts/flama/Flama-Semibold.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "HelveticaNeue";
  src: local("HelveticaNeue"),
    url("./fonts/helvetica/HelveticaNeue.ttf") format("opentype");
}

@font-face {
  font-family: "Argesta";
  src: local("Argesta"),
    url("./fonts/argesta/argesta-display-regular.otf") format("opentype");
}

@import url('https://fonts.googleapis.com/css2?family=Thasadith&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Thasadith:wght@400;700&display=swap');